<template>
    <div class="faq-page_wrapper">
         <div class="faq-page_main">
                 <img class="faq-img" src="@/assets/images/faq.png">
                 <div class="faq-container">
                     <Breadcrumbs
                         :items="items"
                     />
                   <h1 class="faq-title">{{ $t('faq_title') }}</h1>
                   <p class="faq-body" v-html="$t('body')"></p>
                     <div class="faq">
                       <h3>{{$t('acceptability')}}</h3>
                        <div>
                          <div class="faq_text">
                            <span>-</span>
                            <p>{{$t('acceptability_text')}}</p>
                          </div>
                          <div class="faq_text">
                            <span>-</span>
                            <p>{{$t('acceptability_text1')}}</p>
                          </div>
                       </div>
                     </div>
                    <div class="faq">
                     <h3>{{$t('general')}}</h3>
                     <div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('general_text')}}</p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('general_text1')}}</p>
                       </div>
                     </div>
                   </div>
                    <div class="faq">
                     <h3>{{$t('languages')}}</h3>
                     <div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('languages_text')}}</p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('languages_text1')}}</p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('languages_text2')}}</p>
                       </div>
                     </div>
                   </div>
                    <div class="faq">
                      <h3>{{$t('resume_faq')}}</h3>

                      <div>
                        <div class="faq_text">
                          <span>-</span>
                          <p>{{$t('resume_text')}}</p>
                        </div>
                        <div class="faq_text">
                          <span>-</span>
                          <p>{{$t('resume_text1')}}</p>
                        </div>
                        <div class="faq_text">
                          <span>-</span>
                          <p>{{$t('resume_text2')}}</p>
                        </div>
                        <div class="faq_text">
                          <span>-</span>
                          <p>{{$t('resume_text3')}}</p>
                        </div>
                     </div>
                   </div>
                    <div class="faq">
                     <h3>{{$t('certificate_of_registration')}}</h3>
                      <p>{{ $t('certificate_of_registration_body') }}</p>
                      <p>{{ $t('certificate_of_registration_body1') }}</p>
                      <div>
                        <div class="faq_text_number">
                          <span>1.</span>
                          <p>{{$t('certificate_of_registration_text')}}</p>
                        </div>
                        <div class="faq_text_number">
                          <span>2.</span>
                          <p>{{$t('certificate_of_registration_text1')}}</p>
                        </div>
						<div class="faq_text_number">
                          <span>3.</span>
                          <p>{{$t('certificate_of_registration_text2')}}</p>
                        </div>
                     </div>
                   </div>
                    <div class="faq">
                     <h3>{{$t('faq_project')}}</h3>
                      <p>{{ $t('faq_project_body') }}</p>
                   </div>
                   <div class="faq">
                     <h3>{{$t('send_application')}}</h3>
                      <p>{{ $t('send_application_text') }}</p>
                   </div>
                   <div class="faq">
                     <h3>{{$t('results')}}</h3>

                     <div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('results_text')}}</p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p>{{$t('results_text1')}}</p>
                       </div>
                     </div>
                   </div>
                   <div class="faq">
                     <h3>{{$t('faq_help')}}</h3>

                     <div>
                       <div class="faq_text">
                         <span>-</span>
                         <p v-html="$t('faq_help_text')"></p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p v-html="$t('faq_help_text1')"></p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p v-html="$t('faq_help_text2')"></p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p v-html="$t('faq_help_text3')"></p>
                       </div>
                       <div class="faq_text">
                         <span>-</span>
                         <p v-html="$t('faq_help_text4')"></p>
                       </div>
                     </div>
                   </div>
                 </div>
             </div>
    </div>
</template>
<script>
import Breadcrumbs from '@/components/breadcrumbs'
export default {
    components: {
        Breadcrumbs
    },
    data: () => ({
        items: [
            {
                text: 'home',
                href: '/'
            },
            {
                text: 'faq_title',
                href: '#',
            }
        ]
    }),
    mounted () {
      if (this.$route.query.lang) {
        this.$i18n.locale = this.$route.query.lang
      }
    }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;900&display=swap');
.faq-page_main {
  display: flex;
  .faq-img {
    margin: 0 40px 40px 0;
    height: 430px;
  }
  .faq-container {
    width: 706px;
  }
  .faq-title {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 36px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #000000;
    margin-bottom: 45px;
    width: 500px;
  }
  .faq-body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 40px;
  }
  .body-link {
    color: #1F5CFF;
  }
  .faq {
    margin-bottom: 40px;
    h3 {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 34px;
      color: #000000;
      margin-bottom: 15px;
    }
    p {
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #000000;
      margin-bottom: 15px;
    }
    img {
      margin: 40px 0 40px 0;
    }
    .faq_text {
      display: flex;
      span {
        font-size: 29px;
        margin-right: 8px;
        margin-top: 2px;
      }
    }
    .faq_text_number {
      display: flex;
      span {
        font-size: 17px;
        margin-right: 10px;
        margin-top: 2px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .faq-page_main {
    display: block;
    .faq-container {
      width: 100%;
    }
    .faq-title {
      font-size: 22px;
      margin-bottom: 30px;
    }
    .faq-body {
      font-size: 17px;
    }
    .faq {
      h3 {
        font-size: 17px;
      }
      p {
        font-size: 14px;
      }
      .faq_text {

        span {
          font-size: 25px;
        }
      }
      .faq_text_number {
        span {
          font-size: 14px;
        }
      }
    }
  }
}
@media (max-width: 576px) {
  .main-wrapper {
    width: 100% !important;
  }
  .faq-page_wrapper {
    padding: 0 20px;
  }
  .faq-page_main {
    display: flex;
    flex-direction: column;
    .faq-img {
      margin: 40px 40px 40px 0;
    }
  }
}
</style>